<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    single-edit
    authority="Users_Company"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../companyStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
      roleOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'company',
        endpoint: 'company',
        route: 'companies',
        title: {
          single: this.$i18n.t('Company'),
          plural: this.$i18n.t('Companies'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'email', label: this.$i18n.t('email'), sortable: true },
        { key: 'roles', label: this.$i18n.t('roles'), sortable: false },
        { key: 'country.title', label: this.$i18n.t('country'), sortable: false },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'enabled', type: 'boolean' },
        { key: 'roles', type: 'list' },
      ]
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('country'),
          options: this.countryOptions,
          value: null,
        },
        {
          name: 'role',
          label: this.$t('role'),
          options: this.roleOptions,
          value: null,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '4',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '3',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries')
      .then(response => {
        this.countryOptions = response.data
      })
    this.$store.dispatch('app-common/fetchRoles')
      .then(response => {
        this.roleOptions = response.data
      })
  },
}
</script>
